/* TODO: only the TrackingPrinter needs this class, not it's childs */
.bc-printable {
  display: none !important;
}

/* TODO: the class unprintable is unnecessary */
.bc-unprintable{
  display: block !important;
}

@media print {
  app-root > *:not(.bc-printable) {
    display: none !important;
  }

  .bc-unprintable{
    display: none !important;
  }

  .bc-printable {
    display: block !important;
    height: 100%;
    -webkit-print-color-adjust: exact;
  }
}
